;(function (win, undefined){
  var doc           = win.document,
    attachListener  = win.addEventListener ? 'addEventListener' : 'attachEvent',
    detachListener  = win.addEventListener ? 'removeEventListener' : 'detachEvent',
    message         = win.addEventListener ? 'message' : 'onmessage',
    objProto        = Object.prototype,
    objCreate       = Object.create,
    slice           = Array.prototype.slice,
    scripts         = doc.getElementsByTagName( 'script' ) || [],
    neededLibraries = [],
    canUsePostMessage = !!win.postMessage,
    loaderFinished  = false,
    now             = +new Date(),
    catchStr        = 'catch',

    // Create a holding tank for code that will be loaded later
    Globals         = {
      currentVersion: '3.0.4', // WebTag Version Number
      slice: slice,
      iFrame: null,
      Libraries: {
        status: {},
        queue: [],
        states: {
          pending: 1,
          fulfilled: 2
        }
      },
      Promise: null,
      Promises: {
        ids: [],
        objects: {},
        states: {
          pending: undefined,
          fulfilled: 1,
          rejected: 2
        },
        triggerResolver: function ( promise, resolver ){
          // Instead of triggering resolver as normal, just store it for later. Core will replace this method
          promise.__resolver = resolver;
        },
        subscribe: function ( parent, child, onFulfillment, onRejection ){
          var subscribers = parent._subscribers,
            length = subscribers.length;
          subscribers[ length ] = child;
          subscribers[ length + Globals.Promises.states.fulfilled ] = onFulfillment;
          subscribers[ length + Globals.Promises.states.rejected ] = onRejection;
        }
      },
      Errors: {},
      Core: {},
      Events: {
        triggered: [],
        bound: {}
      },

      // Global configuration properties (null values are provided to indicate what is possible)
      config: {
        // Public configurations
        key: null,
        tenantId: null,
        namespace: '$A1',
        secure: null,
        host: null,
        domain: null,
        scheme: 'a1webtag'
      },
      _config: {
        // Private configurations
        protocol: null,
        path: '',
        version: null,
        dataHost: 'api6.agilone.com',
        dataVersion: 'v2',
        contentHost: 'content.agilone.com',
        contentVersion: 'v2'
      },

      // Logger construct. Only contains Globals.logs.put; other methods will be added as part of Core
      logs: {
        logs: [],
        timers: [],
        // logger.put : Add a single entry to the logs
        //   data: Data to store
        //   level: Log level (used for filtering; 1-4)
        //   timer: id of a timer (timers allow for measuring the time between two logs)
        put: function ( data, level, timer ){
          var self = this,
            currentTime = +new Date(),
            main = self.logs[0] ? currentTime - self.logs[0].created_at : 0,
            i, offset;

          level = level || 3;

          function logIt(timer){
            if ( timer ) {
              if ( !self.timers[ timer ] ) {
                self.timers[ timer ] = currentTime;
                offset = 0;
              } else {
                offset = currentTime - self.timers[ timer ];
              }
            }

            self.logs.push({
              created_at: currentTime,
              data: data,
              main: main, // time since self.logs[0].created_at
              id: timer,
              offset: offset, // time since start of timer
              level: level
            });
          }

          if( getType( timer ) === TYPES.ar ){
            i = timer.length;
            while( i-- ){
              logIt( timer[i] );
            }
          }else{
            logIt( timer );
          }
        }
      },
      tagTargets: null
    },

    TYPES,
    nullStr = '[Object Null]',
    undefStr = '[Object Undefined]',
    hop = 'hasOwnProperty',
    hiddenField = 'h',
    cookieKey = 'a1ashgd',
    cookieValue,
    scriptSrc, s, originalObject,
    tokenTO;

  // Basic getType method for a smaller footprint; will be superceeded by the more comprehensive Core.getType
  function getType(val){
    return val === null ? nullStr : val === undefined ? undefStr : objProto.toString.call( val );
  }
  TYPES = {
    st: getType(''),
    nu: getType(0),
    ob: getType({}),
    ar: getType([]),
    fu: getType(noop),
    un: undefStr,
    nl: nullStr,
    dt: getType(new Date())
  };
  function typeErr(msg){
    throw new TypeError(msg);
  }

  Globals.logs.put( 'Library Load Start: Base', null, 'base' );

  function keys(obj){
    var ret = [],
      k;
    if ( getType(obj.keys) === TYPES.fu ) {
      return obj.keys();
    }
    for ( k in obj ) {
      if ( obj[hop](k) === true ) {
        ret.push(k);
      }
    }
    return ret;
  }
  function map(arr, f) {
    var ret,
      l;
    if ( getType(arr.map) === TYPES.fu ) {
      return arr.map(f);
    }
    l = arr.length;
    ret = new Array(l);
    while (l--) {
      ret[l] = f.call(arr[l], l, arr);
    }
    return ret;
  }

  function setCookie() {
    var v = Array(33).join((Math.random().toString(36)+'00000000000000000').slice(2, 18)).slice(0, 32),
      e = new Date();

    e.setTime( e.getTime() + 157680000000 ); // ~5yrs
    if ( Globals.config.domain ) {
      doc.cookie = cookieKey + '=' + v + ';expires=' + e.toGMTString() + ';path=/;domain=' + Globals.config.domain;
    } else {
      doc.cookie = cookieKey + '=' + v + ';expires=' + e.toGMTString() + ';path=/;domain=.' + window.location.hostname.match(/[^\.]*\.[^.]*$/)[0];
    }
    return v;
  }
  function getCookie(){
    var ca = doc.cookie.split( ';' ),
      i = 0, c, name;

    if (cookieValue) {
      return cookieValue;
    }
    name = cookieKey + '=';
    for (; i < ca.length; i++ ) {
      c = ca[ i ];
      while ( c.charAt(0) === ' ' ) {
        c = c.substring(1);
      }
      if ( c.indexOf( name ) === 0 ) {
        return (cookieValue = c.substring( name.length, c.length ));
      }
    }
    return (cookieValue = setCookie());
  }

  // Method responsible for creating the loader iFrame and attaching a listener for messages
  function loader(){
    var src;

    Globals.logs.put( 'loader Start' );

    // Method to handle incoming messages
    function messageHandler(e){
      if ( e.origin === Globals._config.protocol + Globals.config.host || e.origin === Globals._config.protocol + Globals._config.dataHost ) {
        processData( e.data );
      }
    }

    try {
      // Create and hide the iframe container and append it to the document
      Globals.iFrame                = doc.createElement( 'iframe' );
      Globals.iFrame.style.cssText  = 'position:absolute;width:1px;height:1px;left:-9999px;';
      Globals.iFrame.id             = Globals.config.namespace + '_' + now;
      Globals.iFrame.name           = '';
      Globals.iFrame.src            = 'javascript:false'; // jshint ignore:line
      Globals.iFrame.title          = 'a1-loader'; // jshint ignore:line
      Globals.iFrame.tabIndex       = -1; // jshint ignore:line
      Globals.iFrame.ariaHidden = 'true';

      doc.body.appendChild( Globals.iFrame );

    } catch(err) {
      // if the document body does not exist yet (because the script was in the head, for example) or appending a child can't yet happen correctly,
      // wait 50ms and retry the loader method
      Globals.logs.put( 'loader Paused - waiting for document.body to exist and be ready' );
      // Need to call loader in the same context so that we have the same private variables, so we need a reference to "this"
      var self = this,
        args = slice.call( arguments );
      return win.setTimeout( function () {
        loader.apply( self, args );
        // Need to make sure that these are cleared so that the memory can be reclaimed
        self = args = null;
      }, 50 );
    }
    // Check if we have any tags on the page; load the "templates" library if so
    Globals.tagTargets = win.document.querySelectorAll('[data-a1-webtag], [a1-webtag]');
    if ( Globals.tagTargets.length > 0 || ADMIN ) {
      neededLibraries.push( 'template' );
    }

    win[attachListener]( message, messageHandler, false );
    // Define a _detach method to allow for the message handler to be detached manually
    win[ Globals.config.namespace ]._detach = function (){
      win[detachListener]( message, messageHandler );
    };

    src = [ Globals._config.protocol, Globals.config.host, Globals._config.path, 'a1_webtag.html?version=',Globals._config.version,'&namespace=',Globals.config.namespace ];
    Globals.iFrame.src = src.join( '' );

    Globals.logs.put( 'loader End', 2 );
  }

  // Function to process data received from a loader message
  function processData( message ){
    // Run the code coming from the iframe in this execution context
    try {
      message = JSON.parse(message);
    } catch (e) {
      console.log(message);
      try {
        win.console.error( 'AgilOne WebTag - Error in processData while parsing message: ' + e );
      } catch (err) {
        // no-op
      }
    }
    if ( getType( message ) === TYPES.ob ) {
      switch ( message.type ) {
      case 'library':
        Globals.logs.put( 'Library Code Received: ' + message.id, null, message.id );
        try {
          eval( message.data ); // jshint ignore:line
        } catch (e) {
          try {
            win.console.error( 'AgilOne WebTag - Error in processData for ' + message.id +': ' + e );
          } catch (err) {
            // no-op
          }
        }
        // If core is loaded then process any instructions associated with the library,
        // otherwise store that execution in a queue to be evaluated once core is loaded
        if ( Globals.Core.processLibrary ) {
          Globals.Core.processLibrary( message.id );
          win[ Globals.config.namespace ].trigger({ type: 'libraryLoaded', target: message.id });
        } else {
          Globals.logs.put( 'Add library to process queue: ' + message.id, 2, message.id );
          Globals.Libraries.queue.push( message.id );
        }
        Globals.logs.put( 'Load Request Library End: ' + message.id, 2, message.id );
        break;
      case 'function':
        Globals.logs.put( 'Code Received: ' + message.id, null, message.id );
        try {
          eval( message.data ); // jshint ignore:line
        } catch (e) {
          try {
            win.console.error( 'AgilOne WebTag - Error in processData for ' + message.id +': ' + e );
          } catch(err){
            // no-op
          }
        }
        break;
      case 'data':
        if ( getType( Globals.Core.dataHandler ) === TYPES.fu ) {
          Globals.Core.dataHandler( message );
        } else {
          console.log(JSON.stringify(message));
          win.console.error( 'AgilOne WebTag - Data loaded before Core library: ' + message.id );
        }
        break;
      default:
        win.console.error( 'AgilOne WebTag - Unrecognized message type: ' + message.type );
        return;
      }
    }
  }

  // Use a custom Promise shim in order to defer all Promise resolutions until the Core library is loaded
  function noop() {}
  function Promise( resolver ){
    var self = this;
    self._id = Globals.Promises.ids.length;
    self._subscribers = [];
    self._state = Globals.Promises.states.pending;
    // Object to store any libraries required by this Promise
    self.__requiresLib = {};

    if ( resolver !== noop ) {
      if ( getType(resolver) !== TYPES.fu ) {
        typeErr( 'You must pass a resolver function as the first argument to the promise constructor' );
      }
      if ( !(self instanceof Promise) ) {
        return new Promise( resolver );
      }
      // Capture promise instance for re-execution and debugging
      Globals.Promises.objects[ self._id ] = self;
      Globals.Promises.ids.push( self._id );

      Globals.Promises.triggerResolver( self, resolver );
    }
  }

  Promise.prototype = {
    constructor: Promise,
    then: function ( onFulfillment, onRejection ){
      var self = this,
        child;

      child = new self.constructor( noop );
      Globals.Promises.subscribe( self, child, onFulfillment, onRejection );
      return child;
    },
    always: function ( onComplete ){
      return this.then(onComplete, onComplete);
    }
  };
  Promise.prototype[catchStr] = function ( onRejection ){
    return this.then(null, onRejection);
  };

  Globals.Promise = Promise;

  // Method to request that a library file (library or template) be loaded if not already
  function requestLibrary( library ){
    if ( Globals.Libraries.status[ library ] ) {
      return;
    }

    Globals.logs.put( 'Load Request Library Start: ' + library, 3, library );

    Globals.Libraries.status[ library ] = Globals.Libraries.states.pending;
    Globals.iFrame.contentWindow.postMessage(JSON.stringify({
      type: 'library',
      data: library
    }), '*' );
  }

  // Method to request that one or more libraries be loaded.
  function loadLibrary( libs, promise ){
    var i, lib;

    libs = getType(libs) === TYPES.ar ? libs : [libs];

    if ( i = libs.length ) { // jshint ignore:line
      while ( i-- ) {
        lib = libs[i];
        if ( lib ) {
          if ( promise && !Globals.Promises.objects[ promise._id ].__requiresLib[ lib ] ) {
            // if the context of this call is a promise, store the library in the requires object of the promise so we know to load the library before proccessing the promise
            Globals.Promises.objects[ promise._id ].__requiresLib[ lib ] = Globals.Libraries.status[ lib ] || Globals.Libraries.states.pending;
          }
          if ( Globals.Libraries.status[ lib ] === undefined ) {
            // The loaderFinished boolean will be set to true at the end of the loader.js file
            loaderFinished ? requestLibrary( lib ) : neededLibraries.push( lib ); // jshint ignore:line
          }
        }
      }
    }
    return promise || true;
  }
  Globals.Libraries.load = loadLibrary;

  function sendConfig( key, value ) {
    Globals.iFrame.contentWindow.postMessage(JSON.stringify({
      type: 'config',
      data: {
        key: key,
        value: value
      }
    }), '*' );
  }

  // Event functions
  function trigger( event ){
    var eventType = getType(event);
    if ( eventType === TYPES.st ) {
      event = { type: event.toLowerCase() };
      eventType = TYPES.ob;
    }
    if ( eventType === TYPES.ob && getType(event.type) === TYPES.st ) {
      if ( DEBUG ) {
        console.log( (+new Date()) + ' Event triggered', event );
      }
      event._ts = event._ts || new Date();
      Globals.Events.triggered.push( event );
    } else {
      typeErr('"event" must be a string or object with a "type" string');
    }
    return this;
  }
  function bind( type, callback ){
    if ( getType(type) === TYPES.st && getType(callback) === TYPES.fu ) {
      type = type.toLowerCase();
      Globals.Events.bound[type] = Globals.Events.bound[type] || [];
      Globals.Events.bound[type].push({
        ts: new Date(),
        callback: callback
      });
    } else {
      typeErr('"type" must be a string and "callback" must be a function');
    }
    return this;
  }
  function unbind( type, callback ){
    var i = 0, l;
    if ( getType(type) === TYPES.st ) {
      type = type.toLowerCase();
      if ( arguments.length === 1 ) {
        Globals.Events.bound[type] = [];
      } else if ( getType(callback) === TYPES.fu ) {
        if ( getType(Globals.Events.bound[type]) === TYPES.ar && Globals.Events.bound[type].length > 0 ) {
          l = Globals.Events.bound[type].length;
          for(; i<l; i++) {
            if ( Globals.Events.bound[type][i].callback === callback ) {
              Globals.Events.bound[type][i] = undefined;
            }
          }
          if ( Globals.Events.bound[type].length === 0 ) {
            delete Globals.Events.bound[type];
          }
        }
      } else {
        typeErr('"callback" must be a function');
      }
    } else {
      typeErr('"type" must be a string');
    }
    return this;
  }

  function modelExtend( base, target, proto ){
    var name,
      fields, key;
    target.prototype = objCreate( base.prototype );
    target.prototype.constructor = target;
    if ( getType(proto) === TYPES.ob ){
      for ( name in proto ) {
        if ( proto[hop]( name ) === true ) {
          target.prototype[ name ] = proto[ name ];
        }
      }
    }
    target.prototype.__keys = {};
    fields = target.prototype.__fields;
    for (key in fields) {
      if ( fields[hop](key) && fields[key].k && fields[key].k !== hiddenField ) {
        target.prototype.__keys[fields[key].k] = key;
      }
    }
    return target;
  }

  function BaseModel (){
    this.__data = {};
  }
  BaseModel.prototype = {
    constructor: BaseModel,
    __fields: {},
    toString: function (){
      return JSON.stringify(this.__data);
    },
    toJSON: function (){
      var self = this,
        ret = {},
        key, v,
        out, l, len;

      for ( key in self.__data ) {
        if ( self.__data[hop]( key ) ) {
          v = self.__data[key];
          switch ( getType(v) ) {
          case TYPES.fu:
          case TYPES.un:
            continue;
          case TYPES.ar:
            len = v.length;
            out = [];
            for (l = 0; l<len; l++) {
              if ( getType(v[l].toJSON) === TYPES.fu ) {
                out.push( v[l].toJSON() );
              } else {
                out.push( v[l] );
              }
            }
            if ( self.__fields[key] && self.__fields[key].k ) {
              if (self.__fields[key].k !== hiddenField ) {
                ret[self.__fields[key].k] = out;
              }
            } else {
              ret[key] = out;
            }
            break;
          default:
            if ( [TYPES.un, TYPES.nl].indexOf(getType(v)) === -1 && getType(v.toJSON) === TYPES.fu ) {
              v = v.toJSON();
            }
            if ( self.__fields[key] && self.__fields[key].k ) {
              if (self.__fields[key].k !== hiddenField ) {
                ret[self.__fields[key].k] = v;
              }
            } else {
              ret[key] = v;
            }
          }
        }
      }
      return ret;
    },
    get: function ( key ){
      var self = this;
      if ( getType(key) === TYPES.st ) {
        return self.__data[ self.__keys && self.__keys[key] ? self.__keys[key] : key ];
      }
      return self.__data;
    },
    set: function ( key, value ){
      var self = this;
      if ( getType(key) !== TYPES.st ) {
        typeErr('"key" must be a string');
      }
      if ( value === null ) {
        return self.remove(key);
      }
      key = self.__keys && self.__keys[key] ? self.__keys[key] : key;
      if ( self.__fields[key] ) {
        if ( self.__fields[key].t ) {
          if ( self.__fields[key].t === TYPES.dt ) {
            self.__data[key] = new Date(value);
            if ( isNaN( self.__data[key].getTime() ) ) {
              delete self.__data[key];
              typeErr('"value" must be a valid date for the "' + key + '" field');
            }
            return self;
          } else if ( !self.__fields[key].h && self.__fields[key].t !== getType(value) ) {
            typeErr('Invalid "value" provided for the "' + key + '" field - expects an ' + self.__fields[key].t);
          }
        }
        if ( self.__fields[key].h ) {
          if ( self.__fields[key].t && self.__fields[key].t === TYPES.ar && getType(value) !== TYPES.ar ) {
            self.__data[key] = [ self.__fields[key].h.call(self, value, key) ];
          } else {
            self.__data[key] = self.__fields[key].h.call(self, value, key);
          }
          return self;
        }
      }
      self.__data[key] = value;
      return self;
    },
    add: function ( key, value ){
      var self = this,
        fieldType = TYPES.st,
        k, currentValue;

      if ( getType(key) !== TYPES.st ) {
        typeErr('"key" must be a string');
      }
      key = self.__keys[key] || key;
      currentValue = self.__data[key];
      if ( ( self.__fields[key] && self.__fields[key].t === TYPES.ar ) || getType(currentValue) === TYPES.ar ) {
        self.__data[key] = currentValue || [];
        if ( self.__fields[key] && self.__fields[key].h ) {
          value = self.__fields[key].h.call(self, value, key);
        }
        if (getType(value) === TYPES.ar) {
          self.__data[key] = currentValue.concat(value);
        } else {
          self.__data[key].push(value);
        }
      } else if ( ( self.__fields[key] && self.__fields[key].t === TYPES.ob ) || getType(currentValue) === TYPES.ob ) {
        if ( getType(value) === TYPES.ob ) {
          if ( self.__fields[key] && self.__fields[key].h ) {
            self.__data[key] = self.__fields[key].h.call(self, value, key);
          } else {
            self.__data[key] = currentValue || {};
            for ( k in value ) {
              if ( value[hop](k) === true ) {
                self.__data[key][k] = value[k];
              }
            }
          }
        } else {
          typeErr('"value" must be an object for key "' + key + '"');
        }
      } else {
        self.set( key, value );
      }
      return self;
    },
    remove: function ( key, value ) {
      var self = this,
        n,
        k, currentValue;

      if ( getType(key) !== TYPES.st ) {
        typeErr('"key" must be a string');
      }
      key = self.__keys[key] || key;
      currentValue = self.__data[key];
      switch ( getType(value ) ) {
      case TYPES.un:
        self.__data[ key ] = n;
        break;
      case TYPES.nl:
      case TYPES.st:
        if ( ( self.__fields[key] && self.__fields[key].t === TYPES.ar ) || getType(currentValue) === TYPES.ar ) {
          if ( currentValue ) {
            k = currentValue.length;
            while (k--) {
              if ( currentValue[k] === value ) {
                self.__data[key] = currentValue.splice(k, 1);
              }
            }
            if ( currentValue.length === 0 ) {
              self.__data[key] = n;
            }
          }
        } else if ( ( self.__fields[key] && self.__fields[key].t === TYPES.ob ) || getType(currentValue) === TYPES.ob ) {
          if ( currentValue ) {
            delete self.__data[key][ value ];
            if ( keys(currentValue).length === 0 ) {
              self.__data[key] = n;
            }
          }
        } else {
          delete self.__data[ key ];
        }
        break;
      default:
        typeErr('"value" must be a string, null, or undefined');
      }
      return self;
    }
  };
  function setParents(value, key) {
    var self = this;
    if (self.__parents.length > 0) {
      map(self.__parents, function (p){
        if (getType(p.set) === TYPES.fu) {
          p.set(self.__fields[key].k, value);
        }
      });
    }
    return value;
  }

  function Customer(data){
    var self = this,
      k;

    if ( self instanceof Customer ) {
      self.__data = {};
      self.__parents = [];
      switch (getType(data)) {
      case TYPES.st:
      case TYPES.nu:
        self.set('id', data);
        break;
      case TYPES.ob:
        for ( k in data ) {
          if ( data[hop](k) === true ) {
            self.set(k, data[k]);
          }
        }
        break;
      case TYPES.un:
      case TYPES.nl:
        break;
      default:
        typeErr('First argument of ' + Globals.config.namespace + '.Customer() must be a string, number, object, null, or undefined');
      }
    } else {
      return new Customer(data);
    }
  }
  modelExtend( BaseModel, Customer, {
    __fields: {
      id: { k: 'SourceCustomerNumber', h: setParents },
      custom: { t: TYPES.ob, k: 'Custom' },
      preferences: { t: TYPES.ob, k: 'Preferences' }
    }
  });

  function Target(data){
    var self = this,
      k;

    if ( self instanceof Target ) {
      self.__data = {};
      self.__parents = [];
      switch (getType(data)) {
      case TYPES.st:
      case TYPES.nu:
        self.set('productId', data);
        break;
      case TYPES.ob:
        for ( k in data ) {
          if ( data[hop](k) === true ) {
            self.set(k, data[k]);
          }
        }
        break;
      case TYPES.un:
      case TYPES.nl:
        break;
      default:
        typeErr('First argument of ' + Globals.config.namespace + '.Target() must be a string, number, object, null, or undefined');
      }
    } else {
      return new Target(data);
    }
  }
  modelExtend( BaseModel, Target, {
    __fields: {
      productId: { k: 'SourceProductNumber' },
      messageId: { k: 'SourceMessageNumber' },
      listId: { k: 'SourceListNumber' },
      brandId: { k: 'BrandId' },
      reviewId: { k: 'SourceReviewNumber' },
      transactionItemId: { k: 'SourceTransactionItemNumber' },
      transactionId: { k: 'SourceTransactionNumber' },
      type: { k: 'Type' },
      Subtype: { k: 'Subtype' },
      quantity: { k: 'Quantity' },
      shipDate: { t: TYPES.dt, k: 'ShipDate' },
      saleRevenue: { k: 'SaleRevenue' },
      discount: { k: 'Discount' },
      shippingRevenue: { k: 'ShippingRevenue' },
      invoiceDate: { t: TYPES.dt, k: 'InvoiceDate' },
      organizationId: { k: 'SourceOrganizationNumber' },
      variables: { t: TYPES.ob, k: 'Variables' },
      SourceProductCategoryNumber: { k: 'SourceProductCategoryNumber'}
    },
    toJSON: function() {
      var self = this,
        ret;

      ret = BaseModel.prototype.toJSON.apply(self, arguments);
      if (getType(ret.Variables) === TYPES.ob) {
        ret.Variables = JSON.stringify(ret.Variables);
      }
      return ret;
    }
  });

  function Transaction(data){
    var self = this,
      k;

    if ( self instanceof Transaction ) {
      self.__data = {
        type: 'Purchase'
      };
      self.__parents = [];
      switch (getType(data)) {
      case TYPES.st:
      case TYPES.nu:
        self.set('id', data);
        break;
      case TYPES.ob:
        for ( k in data ) {
          if ( data[hop](k) === true ) {
            self.set(k, data[k]);
          }
        }
        break;
      case TYPES.un:
      case TYPES.nl:
        break;
      default:
        typeErr('First argument of ' + Globals.config.namespace + '.Transaction() must be a string, number, object, null, or undefined');
      }
    } else {
      return new Transaction(data);
    }
  }
  modelExtend( BaseModel, Transaction, {
    __fields: {
      id: { k: 'SourceTransactionNumber', h: setParents },
      organizationId: { k: 'SourceOrganizationNumber' },
      // items: { t: TYPES.ar, k: 'TransactionItems', h: addItemsToTransaction },
      Subtype: { k: hiddenField, h: setParents },
      type: { k: hiddenField, h: setParents },
      invoiceDate: { t: TYPES.dt, k: 'TransactionTimeStamp' },
      total: { k: 'Total' },
      discount: { k: 'Discount' },
      tax: { k: 'Tax' }
    }
  });

  function Event(data){
    var self = this,
      k;

    if ( self instanceof Event ) {
      self.__data = {
        URL: doc.location.href,
        Referer: doc.referrer || undefined,
        Cookie: getCookie()
      };
      switch (getType(data)) {
      case TYPES.st:
        self.set('type', data);
        break;
      case TYPES.ob:
        for ( k in data ) {
          if ( data[hop](k) === true ) {
            self.set(k, data[k]);
          }
        }
        break;
      case TYPES.un:
      case TYPES.nl:
        break;
      default:
        typeErr('First argument of ' + Globals.config.namespace + '.Event() must be an object, null, or undefined');
      }
    } else {
      return new Event(data);
    }
  }
  function addCustomerToEvent(value){
    var self = this,
      errText = '"customer" must be either a Customer instance, object, string, number, null, or undefined',
      ret;

    function inherit(obj){
      var id = obj.get('id');
      if (getType(id) !== TYPES.un) {
        if (getType(self.__data.targets) === TYPES.ar && self.__data.targets.length > 0) {
          map(self.__data.targets, function (target) {
            target.set('SourceCustomerNumber', id);
          });
        }
        if (self.__data.transaction instanceof Transaction) {
          self.__data.transaction.set('SourceCustomerNumber', id);
        }
      }
      return obj;
    }

    if (self.__data.customer) {
      self.__data.customer.__parents.splice(self.__data.customer.__parents.indexOf(self) ,1);
    }
    switch( getType(value) ) {
    case TYPES.ob:
      if (value instanceof Customer) {
        ret = value;
      } else if (value instanceof BaseModel) {
        typeErr(errText);
      }
      /* falls through */
    case TYPES.st:
    case TYPES.nu:
      ret = inherit(ret || new Customer(value));
      break;
    case TYPES.un:
    case TYPES.nl:
      return;
    default:
      typeErr(errText);
    }
    ret.__parents.push(self);
    return ret;
  }
  function addTargetsToEvent(value){
    var self = this,
      errText = '"targets" must be one or more (as an array) of either a Target instance, object, string, number, null, or undefined',
      ret;

    function inherit(obj){
      if ( !obj.get('type') ) {
        obj.set('type', self.__data.type);
      }
      if ( !obj.get('Subtype') ) {
        obj.set('Subtype', self.__data.Subtype);
      }
      if ( !obj.get('URL') ) {
        obj.set('URL', self.__data.URL);
      }
      if ( !obj.get('Referer') ) {
        obj.set('Referer', self.__data.Referer);
      }
      if ( !obj.get('Cookie') ) {
        obj.set('Cookie', self.__data.Cookie);
      }
      if ( !obj.get('Variables') && getType(self.__data.variables) === TYPES.ob ) {
        obj.set('Variables', self.__data.variables);
      }
      if ( self.__data.customer instanceof Customer ) {
        obj.set('SourceCustomerNumber', self.__data.customer.get('id'));
      }
      if ( self.__data.transaction instanceof Transaction ) {
        obj.set('SourceTransactionNumber', self.__data.transaction.get('id'));
      }
      if (self.__data.SourceProductCategoryNumber && getType(self.__data.SourceProductCategoryNumber) === TYPES.st) {
        obj.set('SourceProductCategoryNumber', self.__data.SourceProductCategoryNumber);
      }
      if (obj.__parents.indexOf(self) === -1) {
        obj.__parents.push(self);
      }
      return obj;
    }

    switch( getType(value) ) {
    case TYPES.ar:
      ret = [];
      map(value, function (v) {
        var t = addTargetsToEvent.call(self, v);
        if ( getType(t) === TYPES.ar && t[0] instanceof Target ) {
          ret.push(t[0]);
        }
      });
      return ret;
    case TYPES.ob:
      if (value instanceof Target) {
        return [inherit(value)];
      }
      if ( !(value instanceof BaseModel) ) {
        return [inherit(new Target(value))];
      }
      break;
    case TYPES.st:
    case TYPES.nu:
      return [inherit(new Target(value))];
    case TYPES.un:
    case TYPES.nl:
      return;
    }
    typeErr(errText);
  }
  function addTransactionToEvent(value){
    var self = this,
      errText = '"transaction" must be either a Transaction instance, object, string, number, null, or undefined',
      ret;

    if (self.__data.transaction) {
      self.__data.transaction.__parents.splice(self.__data.transaction.__parents.indexOf(self) ,1);
    }

    function inherit(obj){
      var id;

      if ( !obj.get('Subtype') ) {
        obj.set('Subtype', self.__data.Subtype);
      }
      if ( self.__data.customer instanceof Customer ) {
        obj.set('SourceCustomerNumber', self.__data.customer.get('id'));
      }
      if (getType(self.__data.targets) === TYPES.ar && self.__data.targets.length > 0) {
        id = obj.get('id');
        map(self.__data.targets, function (target) {
          target.set('SourceTransactionNumber', id);
        });
      }
      if (obj.__parents.indexOf(self) === -1) {
        obj.__parents.push(self);
      }
      return obj;
    }

    switch( getType(value) ) {
    case TYPES.ob:
      if (value instanceof Transaction) {
        ret = value;
      } else if (value instanceof BaseModel) {
        typeErr(errText);
      }
      /* falls through */
    case TYPES.st:
    case TYPES.nu:
      ret = inherit(ret || new Transaction(value));
      break;
    case TYPES.un:
    case TYPES.nl:
      return;
    default:
      typeErr(errText);
    }
    ret.__parents.push(self);
    return ret;
  }
  function modifyEventTargetsField(value, key){
    var self = this;

    if ( getType(self.__data.targets) === TYPES.ar && self.__data.targets.length > 0 ) {
      map(self.__data.targets, function (target){
        target.set(key, value);
      });
    }
    return value;
  }
  function modifyEventChildrenField(value, key){
    var self = this;

    if ( getType(self.__data.targets) === TYPES.ar && self.__data.targets.length > 0 ) {
      map(self.__data.targets, function (target){
        target.set(key, value);
      });
    }
    if ( self.__data.transaction instanceof Transaction ) {
      self.__data.transaction.set(key, value);
    }
    return value;
  }
  modelExtend( BaseModel, Event, {
    __fields: {
      type: { k: hiddenField, h: modifyEventTargetsField },
      URL: { k: hiddenField, h: modifyEventTargetsField },
      Referer: { k: hiddenField, h: modifyEventTargetsField },
      Cookie: { k: hiddenField, h: modifyEventTargetsField },
      Subtype: { k: hiddenField, h: modifyEventTargetsField },
      customer: { k: hiddenField, h: addCustomerToEvent },
      SourceCustomerNumber: { k: hiddenField, h: modifyEventChildrenField },
      SourceProductCategoryNumber: { k: hiddenField, h: modifyEventChildrenField },
      SourceTransactionNumber: { k: hiddenField, h: modifyEventTargetsField },
      transaction: { k: hiddenField, h: addTransactionToEvent },
      targets: { k: 'events', h: addTargetsToEvent },
      time: { t: TYPES.dt, k: 'EventTimeStamp' },
      location: { t: TYPES.ob, k: 'GeoEncoding' },
      variables: { t: TYPES.ob, k: hiddenField, h: modifyEventTargetsField }
    },
    toJSON: function(){
      var self = this,
        e,
        ret;

      ret = BaseModel.prototype.toJSON.apply(self, arguments);
      if (getType(ret.Variables) === TYPES.ob) {
        ret.Variables = JSON.stringify(ret.Variables);
      }
      if (self.__data.customer) {
        ret.customers = [self.__data.customer.toJSON.apply(self.__data.customer, arguments)];
      }
      if (self.__data.transaction) {
        ret.transactions = [self.__data.transaction.toJSON.apply(self.__data.transaction, arguments)];
        if (self.__data.targets && self.__data.targets.length > 0) {
          ret.transactionitems = ret.events;
          map(ret.transactionitems, function (item) {
            delete item.URL;
            delete item.Referer;
            delete item.Cookie;
          });
        }
        ret.events = [self.__data.transaction.toJSON.apply(addTargetsToEvent.call(self, {})[0], arguments)];
      } else if (self.__data.type && (!self.__data.targets || self.__data.targets.length === 0)) {
        ret.events = [addTargetsToEvent.call(self, {})[0].toJSON()];
      }
      return ret;
    },
    send: function (){
      return win[ Globals.config.namespace ](this.toJSON());
    }
  });

  // Find this <script> tag for this script
  s = scripts.length;
  while( s-- ) {
    if (scripts[ s ] && scripts[ s ].src && scripts[ s ].src.match( /\/a1\.js\?|\/a1\.js$/ ) ) {
      scriptSrc = scripts[ s ].src;
      break;
    }
  }

  if ( getType(objCreate) !== TYPES.fu ) {
    objCreate = (function() {
      var Temp = function() {};
      return function (prototype) {
        Temp.prototype = prototype;
        var result = new Temp();
        Temp.prototype = null;
        return result;
      };
    })();
  }

  // Get the current host default
  Globals.config.secure      = scriptSrc.split( '/' )[0] === 'https:';
  Globals.config.host        = scriptSrc.split( '/' )[2];
  Globals._config.version    = scriptSrc.match( /\/v([\d])\// );
  Globals._config.version    = Globals._config.version === null ? Globals.currentVersion : Globals._config.version[1];

  if (getType(win.$A1Config) === TYPES.ob) {
    map(keys(win.$A1Config), function (key) {
      if (key === 'host') {
        Globals._config.dataHost = win.$A1Config[key];
      } else if (key === 'contentHost') {
        Globals._config.contentHost = win.$A1Config[key];
      } else {
        Globals.config[key] = win.$A1Config[key];
      }
    });
  } else {
    if ( !ADMIN ) {
      typeErr('$A1Config must be an object included in the window before loading the a1.js file');
    }
  }
  Globals._config.dataHost = Globals._config.dataHost.replace(/^(https?:|)\/\//, '');
  Globals._config.contentHost = Globals._config.contentHost.replace(/^(https?:|)\/\//, '');
  Globals._config.version = isNaN( Globals.config.version ) || Globals.config.version === null ? Globals._config.version : parseInt( Globals.config.version );
  Globals._config.path = !Globals.config.path ? ( ADMIN ? '/' : '/v' ) + Globals._config.version + '/' : Globals.config.path;
  Globals._config.protocol = Globals.config.secure ? 'https://' : 'http://';

  Globals._config.dataPath = Globals._config.protocol + Globals._config.dataHost + '/' + Globals._config.dataVersion + '/' + Globals.config.tenantId + '/dw/tracker?scheme=' + Globals.config.scheme + '&accessKey=' + Globals.config.key;

  Globals.logs.put( 'A1 WebTag configured with the following options: ', 3, 'base' );
  Globals.logs.put( Globals.config, 3, 'base' );

  // Store the original page object in case we need it later
  originalObject = win[ Globals.config.namespace ];
  // If the original page object has a _detach method (which should mean it's an A1 object),
  //    call it so that we unbind the message event listener and don't get data corruption
  if ( originalObject && getType( originalObject._detach ) === getType( getType ) ) {
    originalObject._detach();
  }

  // Tracking method
  win[ Globals.config.namespace ] = function ( eventData ){
    return new Promise(function ( success, failure ){
      if ( getType(eventData) !== TYPES.ob ) {
        failure();
      } else {
        win[ Globals.config.namespace ].trigger({
          type: 'track',
          data: eventData,
          cbs: {
            success: success,
            failure: failure
          }
        });
      }
    });
  };
  // Promise util methods
  win[ Globals.config.namespace ].all = function (){
    var promises = slice.call(arguments, 0);
    return new Promise(function ( success, failure ){
      Globals.Promises.all(promises, success, failure);
    });
  };

  // JS event methods
  win[ Globals.config.namespace ].trigger = trigger;
  win[ Globals.config.namespace ].bind = bind;
  win[ Globals.config.namespace ].unbind = unbind;
  win[ Globals.config.namespace ].one = function ( type, callback ){
    var cb = function (){
      callback.apply( win[ Globals.config.namespace ], arguments );
      win[ Globals.config.namespace ].unbind( type, cb );
    };
    if ( getType(callback) === TYPES.fu ) {
      return win[ Globals.config.namespace ].bind( type, cb );
    } else {
      typeErr('"callback" must be a function');
    }
  };

  win[ Globals.config.namespace ].showLogs = function (){
    // no-op until after core is loaded
    return Globals;
  };

  win[ Globals.config.namespace ].BaseModel = BaseModel;
  win[ Globals.config.namespace ].Customer = Customer;
  win[ Globals.config.namespace ].Target = Target;
  win[ Globals.config.namespace ].Transaction = Transaction;
  win[ Globals.config.namespace ].Event = Event;

  if ( canUsePostMessage ) {
    // Load the Core library by default
    loader();
  }

  Globals.logs.put( 'Library Load End: Base', 4, 'base' );

})(window);

